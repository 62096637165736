import React from 'react';
import AdminRoutes from './Routes/AdminRoutes';
import Routing from './Routes/Routes.routes';
import { useEffect } from 'react';


const App = () => {

  
  return (
    <div>
      {/* <AdminRoutes /> */}
      <Routing/>
    </div>
  );
}

export default App;
